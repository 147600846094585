import Alert from '@mui/material/Alert';
import styles from "./MissOfParameters.module.css";
import logo from "../../../src/pages/Visitante/Faq/logo.png";
interface MissOfParametersProps {
    typeUser: string,
    screen: string
}

export default function MissOfParameters({ typeUser, screen }: MissOfParametersProps) {
    return (
        <section className={styles.MissOfParameters}>
            <Alert severity="warning">
                Oi, {typeUser}! Você abriu essa tela diretamente
                pelo link e ela precisa de informações vindo de outra tela!
                {screen === "detalhes-campanha" && <>
                    {typeUser === "influenciador" ? <span> Por favor, clique <a href="/influenciador/minhas-campanhas"> aqui </a> para visualizar suas campanhas.
                        Para ver suas campanhas disponíveis clique <a href="/influenciador/campanhas-disponiveis"> aqui</a>.
                        Para ver suas campanhas finalizadas clique <a href="/influenciador/campanhas-finalizadas"> aqui </a>.
                        Ou se for ver a faturas de suas campanhas clique <a href="/influenciador/fatura"> aqui </a>.
                    </span> : <span> Por favor, clique <a href="/anunciante/minhas-campanhas"> aqui </a> para visualizar suas campanhas.                        
                    </span>}
                </>}
                {screen === "ver-banners" && <>
                    <span > Por favor, clique <a href="/influenciador/minhas-campanhas"> aqui </a> para visualizar os banners de cada campanha. </span>
                </>}
                {screen === "enviar-trabalho" && <>
                    <span > Por favor, clique <a href="/influenciador/minhas-campanhas"> aqui </a> para poder enviar seu trabalho finalizado. </span>
                </>}

                {screen === "estatisticas-da-campanha" && <>
                    {typeUser !== "anunciante" ? <>
                        <span > Por favor, clique <a href="/influenciador/minhas-campanhas"> aqui </a> para poder ver as estatísticas de sua campanha. </span>
                    </> : <>
                        <span > Por favor, clique <a href="/anunciante/minhas-campanhas"> aqui </a> para poder ver as estatísticas de sua campanha. </span>
                    </>}
                </>}

                {screen === "criar-nota-fiscal" && <>
                    <span > Por favor, clique <a href="/influenciador/fatura"> aqui </a> para poder ver suas faturas. </span>
                </>}
                {screen === "chat-proposta" && <>
                    {typeUser === "influenciador" ? <span> Por favor, clique <a href="/influenciador/minhas-campanhas"> aqui </a> para visualizar suas campanhas e encontrar o chat de cada uma delas.
                        Para ver o chat de suas campanhas disponíveis clique <a href="/influenciador/campanhas-disponiveis"> aqui</a>.
                        Ou para ver de suas campanhas finalizadas clique <a href="/influenciador/campanhas-finalizadas"> aqui</a>.
                    </span> : <span> Por favor, clique <a href="/anunciante/influenciadores-em-analise"> aqui </a> para ver os influenciadores em análise. 
                        Ou clique <a href="/anunciante/aguardando-confirmacao"> aqui </a> para ver os influenciadores aguardando confirmação.
                        E assim iniciar uma conversa.
                    </span>}
                </>}
                {screen === "criar-imagem-campanha" && <>
                    <span> Por favor, clique <a href="/anunciante/criar-campanha"> aqui </a> para poder criar sua campanha e só assim criar
                        a imagem da campanha.
                    </span>
                </>}
                {screen === "editar-campanha" && <>
                    <span> Por favor, clique <a href="/anunciante/minhas-campanhas"> aqui </a> para poder editar suas campanhas. </span>
                </>}
                {screen === "adicionar-banners" && <>
                    <span> Por favor, clique <a href="/anunciante/minhas-campanhas"> aqui </a> para poder adicionar banners em suas campanhas. </span>
                </>}
                {screen === "perfil-influenciador" && <>
                    <span> Por favor, clique <a href="/anunciante/influenciadores-em-analise"> aqui </a> para ver os influenciadores em análise. 
                        Ou clique <a href="/anunciante/aguardando-confirmacao"> aqui </a> para ver os influenciadores aguardando confirmação.
                    </span>
                </>}
                {screen === "finalizar-trabalho-influenciador" && <>
                    <span> Por favor, clique <a href="/anunciante/aguardando-confirmacao"> aqui </a> verificar o trabalho finalizado do influenciador. </span>
                </>}
            </Alert>

            <aside>
                <img src={logo} alt='logo ism' />
            </aside>
        </section>
    )
}