import React from "react";
import cupom from "./image/coupons.png";

interface CardsSolicitacoesModeradorProps {
    numeroSolicitacoes: number;
}

const CardsSolicitacoesModerador: React.FC<CardsSolicitacoesModeradorProps> = ({ numeroSolicitacoes }) => {
    return (
        <div className="card" style={{ width: "18rem" }}>
            <img className="card-img-top" src={cupom} alt="Card image cap" />
            <div className="card-body">
                <p className="card-text">
                    Número de novas solicitações: {numeroSolicitacoes}
                </p>
            </div>
        </div>
    );
};

export default CardsSolicitacoesModerador;
