import styles from "./CardsPainel.module.css";

interface CardsAnuncianteProps {
    titulo: string,
    valor: number | string,
    imagem: string,
    dinheiro?: boolean,    
    informaçãoDisponívelAposPerfilSerAceito?: boolean,
}

export default function CardsAnunciante({ titulo, valor, imagem, dinheiro, informaçãoDisponívelAposPerfilSerAceito }: CardsAnuncianteProps) {
    return (
        <div className={styles.CardsPainel}>
            <img src={imagem} alt="megafone"/>
                <div>
                    <p className="card-text">{titulo}: {dinheiro && "R$"}{valor}</p>
                    {informaçãoDisponívelAposPerfilSerAceito && <p className={styles.InformacaoDisponivel}> Informação disponível após seu perfil ser aceito. </p>}
                </div>
        </div>
    );
}