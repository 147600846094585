import { collection, getDocs } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { db } from "../../../../db/banco";
import ISolicitarCupom from "../../../../types/ISolicitarCupom";
import styles from "./InicioModerador.module.css";
import CardsSolicitacoesModerador from "./CardsSolicitacoesModerador";

export default function InicioModerador() {
    const [numeroSolicitacoesCupom, setNumeroSolicitacoesCupom] = useState(0);
    // banco de dados
    const useCollectionRef = collection(db, "solicitarCupom");
    // verifica se o email já é cadastrado
    useEffect(() => {
        const obterSolicitacoesCupom = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasSolicitacoesCupom: ISolicitarCupom[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ISolicitarCupom);
            const somenteNaoVistas: ISolicitarCupom[] = todasSolicitacoesCupom.filter(somenteFalse => somenteFalse.solicitacaoVista === false);
            setNumeroSolicitacoesCupom(somenteNaoVistas.length);
        };
        obterSolicitacoesCupom();
    }, [])
    return (
        <section className={styles.InicioModerador}>
            <h2> Início </h2>
            <p> Bem vindo de volta, Moderador! </p>

            <h3> Sessão solicitações </h3>

            <CardsSolicitacoesModerador numeroSolicitacoes={numeroSolicitacoesCupom}/>
        </section>
    )
}