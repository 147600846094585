import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import IChatPropostas from '../../../../../types/ICharPropostas';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TipoCampanha from '../../../../Anunciante/EstatisticasDeCampanha/TipoCampanha';
import { listaTiposDeCampanha } from '../../../../../utils/listas';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import { obterTodosInfluenciadores } from '../../../../../utils/db';
import autenticaStore from '../../../../../stores/autentica.store';
import { Tooltip } from '@mui/material';
import FeedIcon from '@mui/icons-material/Feed';
import OutboxIcon from '@mui/icons-material/Outbox';
import styles from "./DadosCampInflu.module.css";

interface TabelaInfluenciadorProps {
    campanhas?: IChatPropostas[],
    tem?: boolean
}

export default function DadosCampInflu({ campanhas, tem }: TabelaInfluenciadorProps) {
    const [tabela, setTabela] = useState(true);
    const email = autenticaStore.getEmail();
    const [codigoInfluenciador, setCodigoInfluenciador] = useState(0);

    useEffect(() => {
        function largura() {
            if (window.innerWidth < 650) setTabela(false);
        }
        largura();
    }, [])

    // Carrega dados do anunciante
    useEffect(() => {
        const carregarAnunciantes = async () => {
            const todosInfluenciadores = await obterTodosInfluenciadores();
            const filtrarSomenteEsseInfluenciador = todosInfluenciadores.filter(somenteEsseInfluenciador => somenteEsseInfluenciador.email === email);
            filtrarSomenteEsseInfluenciador.map(pegarDados => {
                setCodigoInfluenciador(pegarDados.codigoActionpayInfluenciador);
            })
        };
        carregarAnunciantes();
    }, [email]);

    return (
        <>
            <h2> Resultado da pesquisa </h2>
            {tabela ? <table className="table table-light table-striped text-center ">
                <thead>
                    <tr>
                        <th scope="col" className="text-white"> Nome </th>
                        <th scope="col" className="text-white"> Tipo </th>
                        {/* <th scope="col" className="text-white"> Payout </th> */}
                        <th scope="col" className="text-white"> Criado em </th>
                        <th scope="col" className="text-white"> Detalhes </th>
                        <th scope="col" className="text-white"> Status </th>
                    </tr>
                </thead>
                {campanhas?.map(cel => (

                    <tbody>
                        <tr>
                            <td> <p> {cel.nomeCampanha} </p> </td>
                            <td> <p> {cel.tipoCampanha} </p> </td>
                            {/* <td> R$250,00 </td> */}
                            <td>  {cel.criadoEm.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </td>
                            <td>
                                <div className="divAcoesTabela">
                                    <Tooltip title="Detalhes da campanha.">
                                        <a href={`/influenciador/detalhes-campanha?id=${cel.idCampanha}&status=${cel.statusProposta}`}> <ArrowForwardOutlinedIcon /> </a>
                                    </Tooltip>
                                    <Tooltip title="Ver banners.">
                                        <a href={`/influenciador/ver-banners?id=${cel.idCampanha}&email=${cel.emailAnunciante}`} > <FeedIcon className='iconeBanner' /> </a>
                                    </Tooltip>
                                    {tem &&
                                        <>
                                            {cel.tipoCampanha === listaTiposDeCampanha[2] || cel.tipoCampanha === listaTiposDeCampanha[3]
                                                || cel.tipoCampanha === listaTiposDeCampanha[4] ? <a href={`/influenciador/estatisticas-da-campanha?id=${cel.codigoActionpay}&tp=influ&idInflu=${codigoInfluenciador}`}> <EqualizerOutlinedIcon style={{ color: 'orange' }} /> </a> :

                                                <Tooltip title="Enviar comprovante de trabalho.">
                                                    <a href={`/influenciador/enviar-trabalho?id=${cel.idCampanha}`} >
                                                        <OutboxIcon style={{ color: '#A35C7A' }} />
                                                    </a>
                                                </Tooltip>
                                            }
                                        </>
                                    }
                                </div>
                            </td>
                            <td>
                                <span
                                    className={
                                        cel.statusProposta === "aceito"
                                            ? styles.CelStatusAceito
                                            : cel.statusProposta === "espera"
                                                ? styles.CelStatusEspera
                                                : styles.CelStatusRejeitado
                                    }
                                >
                                    {cel.statusProposta === "aceito" ? "aceito" : cel.statusProposta === "espera" ? "em análise" : "recusado"}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table> : <div>
                {campanhas?.map(cel => (
                    <ul className="lista">
                        <li className="Itemlista"> . </li>
                        <li className="Itemlista"> <strong> Nome: </strong> {cel.nomeCampanha}  </li>
                        <li className="Itemlista"> <strong> Tipo: </strong> {cel.tipoCampanha}  </li>
                        {/* <li className="Itemlista"> <strong> Payout: </strong> R$250,00 </li> */}
                        <li className="Itemlista"> <strong> Criado em: </strong> {cel.criadoEm.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </li>
                        <li className="Itemlista">
                            <div className="divAcoes">
                                <strong> Detalhes: </strong>
                                <a href={`/influenciador/detalhes-campanha?id=${cel.idCampanha}&status=${cel.statusProposta}`}> <ArrowForwardOutlinedIcon /> </a>
                                <Tooltip title="Ver banners.">
                                    <Link to={`/influenciador/ver-banners?id=${cel.idCampanha}&email=${cel.emailAnunciante}`} > <FeedIcon className='iconeBanner' /> </Link>
                                </Tooltip>
                                {tem && <>
                                    {cel.tipoCampanha === listaTiposDeCampanha[2] || cel.tipoCampanha === listaTiposDeCampanha[3]
                                        || cel.tipoCampanha === listaTiposDeCampanha[4] ? <a href={`/influenciador/estatisticas-da-campanha?id=${cel.codigoActionpay}&tp=influ&idInflu=${codigoInfluenciador}`}> <EqualizerOutlinedIcon style={{ color: 'orange' }} /> </a> :
                                        <a href={`/influenciador/enviar-trabalho?id=${cel.idCampanha}`} >
                                            <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                        </a>}
                                </>}

                            </div>
                        </li>
                        <li className="Itemlista">
                        <strong> Status: </strong>
                            <span
                                className={
                                    cel.statusProposta === "aceito"
                                        ? styles.CelStatusAceito
                                        : cel.statusProposta === "espera"
                                            ? styles.CelStatusEspera
                                            : styles.CelStatusRejeitado
                                }
                            >
                                {cel.statusProposta === "aceito" ? "aceito" : cel.statusProposta === "espera" ? "em análise" : "recusado"}
                            </span>
                        </li>
                    </ul>
                ))}
            </div>}

        </>
    )
}