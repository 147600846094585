import { CollectionReference, DocumentData, addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import styles from "./FinalizarTrabalhoDoInfluenciador.module.css";
import { db } from "../../../db/banco";
import { useEffect, useState } from "react";
import IChatPropostas from "../../../types/ICharPropostas";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import autenticaStore from "../../../stores/autentica.store";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from "axios";
import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoAnunciante from "../../UsuarioNaoAceito/UsuarioNaoAceitoAnunciante";
import ChatIcon from '@mui/icons-material/Chat';
import MissOfParameters from "../../../components/MissOfParameters";

export default function FinalizarTrabalhoDoInfluenciador() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();
    const navigate = useNavigate();
    const email = autenticaStore.getEmail();
    // banco de dados
    const useCollectionRef = collection(db, "chatPropostas");
    // Recebe o chat proposta
    const [proposta, setProposta] = useState<IChatPropostas[]>([]);
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id"); // Parâmetro da página
    // id campanha e email influenciador
    const [idCampanha, setIdCampanha] = useState("");
    const [emailInfluenciador, setEmailInfluenciador] = useState("");
    const [nomeDoInflueciandor, setNomeDoInflueciador] = useState("");
    const [nomeDaCampanha, setNomeDaCampanha] = useState("");
    const [linksTrabalhoJaEnviados, setLinksTrabalhoJaEnviados] = useState([""]);
    const [idChat, setIdChat] = useState("");

    // puxar dados
    useEffect(() => {
        const obterCampanhas = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: IChatPropostas[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IChatPropostas);

            const somenteEssaProposta: IChatPropostas[] = todasCampanhas.filter(somenteEssa => somenteEssa.id === parametro);

            setProposta(somenteEssaProposta);
            somenteEssaProposta.map(idC => {
                setIdCampanha(idC.idCampanha);
                setEmailInfluenciador(idC.emailInfluenciador);
                setNomeDoInflueciador(idC.nomeInfluenciador);
                setNomeDaCampanha(idC.nomeCampanha);
                setLinksTrabalhoJaEnviados(idC.linksTrabalho);
                setIdChat(idC.id);
            })

        }
        obterCampanhas();
    }, [])

    const [caminhoPdf, setCaminhoPdf] = useState<string[]>([""]);

    useEffect(() => {
        if (emailInfluenciador && idCampanha) {
            const storage = getStorage();
            const listRef = ref(storage, `gs://ism-teste.appspot.com/influenciador/${emailInfluenciador}/${idCampanha}/comprovantesCampanha`);

            const listaCaminhosPdfs = [];

            listAll(listRef)
                .then((res => {
                    const paths = res.items.map(itemRef => itemRef.fullPath);
                    setCaminhoPdf(paths);
                }));


        }
    }, [emailInfluenciador, idCampanha]);


    function baixarPdf(url: any) {
        window.open(url);
    }

    function pegaCaminho(caminho: string) {

        const caminhoEncontrado = caminho;
        criaDownload(caminhoEncontrado);
    }

    function criaDownload(caminhoRecebido: any) {
        const storage = getStorage();

        getDownloadURL(ref(storage, caminhoRecebido))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.open('GET', url);
                xhr.send();
                baixarPdf(url);
            });
    }

    // Aceitar e finalizar trabalho

    async function editarChamado(novosDados: any) {
        try {
            if (parametro) {
                const chamadoDocRef = doc(db, "chatPropostas", parametro);

                await updateDoc(chamadoDocRef, novosDados);
                // alert("Dados editados");
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    const enviarEmail = async () => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: emailInfluenciador,
                    assunto: "Oii, influenciador! Temos um recado importante!",
                    mensagem: `Oii, ${nomeDoInflueciandor}\n\n` +
                        "O anunciante confirmou seu trabalho entregue.\n\n" +
                        "Confirme seus dados bancários eu seu perfil.\n\n" +
                        "O pagamento será liberado em breve! 💜\n\n" +
                        "Equipe ISM"
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            // console.log(error);
        }
    };

    async function aceitarProposta() {
        criarNovaNotificacao();
        enviarEmail();
        const conversa = {
            statusProposta: "feito"
        }
        await editarChamado(conversa)
        alert("Trabalho marcado como feito.");
        navigate("/anunciante/influenciadores-em-analise")

    }

    // banco de dados
    const useCollectionRefNotificacao = collection(db, "notificacao");

    async function criarNovaNotificacao() {
        const notificacao = await addDoc(useCollectionRefNotificacao, {
            mensagem: `O anunciante, marcou como finalizado, seu trabalho na campanha: ${nomeDaCampanha}`,
            visto: false,
            tipoDoUsuarioQueVaiReceberNotificacao: "anunciante",
            emailDeQuemVaiReceberNotificacao: `${emailInfluenciador}`,
            data: new Date().toLocaleDateString(),
            link: "https://influenciesuamarca.com.br/influenciador/campanhas-finalizadas"
        })
    }

    function irParaChat() {
        navigate(`/anunciante/chat-proposta?id=${idChat}&tp=anun`);
    }

    if (parametro === null) return <MissOfParameters typeUser="anunciante" screen="finalizar-trabalho-influenciador" />

    return (
        <>
            {statusPlataforma === "aceito" ? <section className={styles.FinalizarTrabalhoDoInfluenciador}>
                <h2> Finalizar trabalho do influenciador </h2>
                {proposta.length === 0 && <span> Esse influenciador ainda não enviou comprovante do trabalho realizado </span>}

                {proposta.map(completa => (
                    <>
                        <h3> O influenciador {completa.nomeInfluenciador} marcou a campanha {completa.nomeCampanha} como finalizada. </h3>
                        <h4> Veja abaixo os comprovantes dos serviços realizados </h4>
                    </>
                ))}

                <ul>
                    {caminhoPdf.map(pdf => {
                        const partes = pdf.split('/comprovantesCampanha/');
                        const nomeArquivo = partes.length > 1 ? partes[1] : pdf; // Pega a parte após '/comprovantesCampanha/' ou usa o caminho completo se não encontrar

                        return (
                            <li onClick={() => pegaCaminho(pdf)} key={pdf}>
                                <FileDownloadIcon fontSize="medium" className={styles.Icone} />
                                <label>{nomeArquivo}</label>
                            </li>
                        );
                    })}
                </ul>


                {linksTrabalhoJaEnviados.length > 0 ?
                    <>
                        <h4> Links enviados pelo influenciador </h4>
                        {linksTrabalhoJaEnviados.filter(link => link).map(link => (
                            <h5 key={link}> <a href={link} target="_blank" rel="noopener noreferrer">
                                {link}
                            </a></h5>
                        ))}
                    </> : <h5> Nenhum link enviado até o momento </h5>}

                <div className={styles.ContainerConfirmarCampanha}>
                    <h4> Deseja confirmar o trabalho desse influenciador, ou entrar em contato com ele? </h4>
                    <div className={styles.ContainerBotao}>
                        <button className="btn btn-success" onClick={aceitarProposta}> Aceitar </button>
                        <button className="btn btn-info" onClick={irParaChat}> <ChatIcon /> chat </button>
                    </div>
                </div>

            </section> : <UsuarioNaoAceitoAnunciante />}
        </>
    )
}


