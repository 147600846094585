import styles from "./Login.module.css";
import CampoTexto from "../../../components/CampoTexto";
import { useEffect, useState } from "react";
import usuario from "./usuario.png";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link, useNavigate } from "react-router-dom";
import Alertas from "../../../components/Alertas";
import 'firebase/auth';
import { useSignInWithEmailAndPassword, useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
import { auth, db } from "../../../db/banco";
import autenticaStore from "../../../stores/autentica.store";
import { collection, getDocs } from "firebase/firestore/lite";
import IAnunciante from "../../../types/IAnunciante";
import checksacceptance from "../../../stores/isAccepted";

// Verificar se tem formas melhores de login
export default function Login() {
    // Controlar o visitante tentando logar
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [
        signInWithEmailAndPassword,
        user,
        loading,
        error

    ] = useSignInWithEmailAndPassword(auth);
    // tirar depois
    const [credenciais, setCredenciais] = useState(false);
    // Define se é anunciante ou influenciador
    const [tipoDeConta, setTipoDeConta] = useState("")
    // Usado para navegar entre rotas
    const navigate = useNavigate();
    // recuperar senha
    const [sendPasswordResetEmail, sending] = useSendPasswordResetEmail(
        auth
    );
    const actionCodeSettings = {
        url: 'influenciesuamarca.com.br/login',
    };

    const useCollectionRefAnunciante = collection(db, "anunciante");

    async function autentifica(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(email, senha);

            if (userCredential && userCredential.user) {
                console.log("Usuário autenticado", userCredential.user);
                autenticaStore.login({ email: email, token: senha });
                const emailRecebido = userCredential.user.email;
                console.log(emailRecebido);

                // Obtém o usuário de forma assíncrona
                const obterUsuario = async () => {
                    const dataBD = await getDocs(useCollectionRefAnunciante);
                    const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante);
                    const ehAnunciante = todosAnunciantes.filter(achou => achou.email === email);
                    console.log("verifica", ehAnunciante.length);

                    if (ehAnunciante.length === 0) return "influenciador";
                    return "anunciante";
                };

                // Aguarda a obtenção do tipo de conta
                const tipoDeConta = await obterUsuario();

                console.log("tipo", tipoDeConta);

                // Navega para a página apropriada após a obtenção do tipo de conta
                checksacceptance.login({ email: email, typeUser: tipoDeConta} )
                if (tipoDeConta) {
                    navigate(`/${tipoDeConta}/inicio`);
                }
            } else {
                console.error("Dados de autenticação não encontrados.");
            }
        } catch (error) {
            console.error("Erro ao autenticar:", error);
        }
    }

    return (
        <section className={styles.Login}>
            {/* Colocar a função autentifica */}
            <form onSubmit={autentifica}>
                <img src={usuario} alt="icone pessoa" />
                <h2> Entrar </h2>
                <CampoTexto
                    titulo=""
                    valor={email}
                    controle={(valor) => setEmail(valor)}
                    icone={<MailOutlineIcon fontSize="large" />}
                    placeholder="EMAIL"
                    type="email"
                    minLength={11}
                />

                {!email && <p> Preencha esse campo </p>}
                {email.length < 11 ? <p> Número mínimo de caracteres: 11 </p> : ""}

                <CampoTexto
                    titulo=""
                    valor={senha}
                    controle={(valor) => setSenha(valor)}
                    icone={<LockOutlinedIcon fontSize="large" />}
                    placeholder="SENHA"
                    type="password"
                    minLength={8}
                />

                {!senha && <p> Preencha esse campo </p>}
                {senha.length < 8 ? <p> Número mínimo de caracteres: 8 </p> : ""}

                {error && <Alertas controle={setCredenciais} mensagem="Credenciais erradas!" tipoMensagem="warning" />}

                <aside>
                    <button className="btn btn-outline-secondary btn-lg">
                        Logar
                    </button>

                    {error && <Alertas controle={setCredenciais} mensagem="Atenção em letras maiúsculas!" tipoMensagem="warning" />}
                    {error && <Alertas controle={setCredenciais} mensagem="Atenção em caracteres especiais!" tipoMensagem="warning" />}
                </aside>



            </form>

            <h3> Não possui um cadastro ? </h3>
            <Link to="/cadastrar"> <h4> Cadastre-se aqui </h4> </Link>

            <h3> Esqueceu sua senha ? </h3>
            <h4 onClick={async () => {
                const success = await sendPasswordResetEmail(email)
                if (success) {
                    alert('Sent email');
                } else {
                    alert("Não deu certo")
                }
            }}> Clique aqui </h4>
        </section>
    );
}