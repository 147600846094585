import { collection, getDocs } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { db } from "../../../../db/banco";
import IChatPropostas from "../../../../types/ICharPropostas";
import { action } from "mobx";
import IInfluenciador from "../../../../types/IInfluenciador";
import { obterTodosInfluenciadores } from "../../../../utils/db";
import styles from "./ListaChatCampanhaDev.module.css";

export default function ListaChatCampanhaDev() {
    const [chatPropostas, setChatPropostas] = useState<IChatPropostas[]>([]);
    // puxar dados
    const useCollectionReff = collection(db, "chatPropostas");
    useEffect(() => {
        const obterCampanhas = async () => {
            const dataBD = await getDocs(useCollectionReff);
            const todasCampanhas: IChatPropostas[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IChatPropostas)

            setChatPropostas(todasCampanhas);
        }
        obterCampanhas();
    }, [])

    // recebe o influenciador
    const [Influenciadores, setInfluenciadores] = useState<IInfluenciador[]>([]);
    // Carrega dados dos influenciadores
    useEffect(() => {
        const carregarInfluenciadores = async () => {
            const todosInfluenciadores = await obterTodosInfluenciadores();
            setInfluenciadores(todosInfluenciadores)
        };
        carregarInfluenciadores();
    }, []);

    return (
        <section className={styles.ListaChatCampanhaDev}>            
            <table>
                <thead>
                    <tr>
                        <th scope="col" className="text-white"> Nome Campanha </th>
                        <th scope="col" className="text-white"> Email influenciador </th>
                        <th scope="col" className="text-white"> ID influenciador </th>
                        <th scope="col" className="text-white"> Status influenciador plataforma </th>
                    </tr>
                </thead>
                {chatPropostas.map(chat => (
                    <tbody>
                        <tr>
                            <td> <span style={{ color: chat.nomeCampanha === "" ? "red" : "inherit" }}> {chat.nomeCampanha ? chat.nomeCampanha : "SEM NOME CAMPANHA"} </span> </td>
                            <td> <span style={{ color: chat.emailInfluenciador === "" ? "red" : "inherit" }}> {chat.emailInfluenciador ? chat.emailInfluenciador : "SEM E-MAIL"} </span> </td>
                            <td> <span style={{ color: chat.idInfluenciador === "" ? "red" : "inherit" }}> {chat.idInfluenciador ? chat.idInfluenciador : "SEM ID INFLUENCIADOR"} </span> </td>
                            <td> {(() => {
                                const influenciador = Influenciadores.find(
                                    influ => influ.email === chat.emailInfluenciador
                                );
                                return influenciador ? (
                                    <span style={{ color: influenciador.aceito !== "aceito" ? "red" : "inherit" }}>
                                        {influenciador.aceito}
                                    </span>

                                ) : 'Nome não encontrado';
                            })()} </td>
                        </tr>
                    </tbody>
                ))}
            </table>
        </section>

    )
}