import { Modal, Box, Typography, Alert, Button } from "@mui/material";
import { title } from "process";
import { Link } from "react-router-dom";

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useState } from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { doc, updateDoc } from "firebase/firestore/lite";

import styles from "./ModalsCoupon.module.css";
import CampoTexto from "../../../../../../../components/CampoTexto";
import { db } from "../../../../../../../db/banco";
import { estiloModalPadrao } from "../../../../../../../styles";

interface ModalsProps {
    openModal: boolean;
    openModalCoupon: () => void;
    closeModalCoupon: () => void;
    typeofAction: string;    
    influenciador: string;
    id: string;
    setAtualiza: (value: boolean) => void;
    atualiza: boolean
}

export default function ModalCouponNominais({ openModal, openModalCoupon, closeModalCoupon, typeofAction, influenciador, id, setAtualiza, atualiza }: ModalsProps) {
    const [data, setData] = useState("");
    const [mensagemSucesso, setMensagemSucesso] = useState(false);

    function atualizarDados() {
        const chatProposta = {
            cupomNominal: data
        }
        editarChatProposta(chatProposta);
    }

    async function editarChatProposta(novosDados: any) {
        try {
            const anuncianteRef = doc(db, "chatPropostas", id);
            await updateDoc(anuncianteRef, novosDados);
            setAtualiza(!atualiza);
            setData("")
            alert("Dados do cupom atualizados!")

        } catch (error) {

        }
    }

    return (
        <Modal
            open={openModal}
            onClose={openModalCoupon}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={estiloModalPadrao}> 
                <p className={styles.Texto}> {typeofAction} cupom nominal do influenciador {influenciador}. </p> 

                <CampoTexto
                    titulo="Cupom nominal"
                    valor={data}
                    controle={(valor) => setData(valor)}
                    icone={typeofAction === "Adicionar" ? <AddCircleIcon fontSize="large" /> : <DriveFileRenameOutlineIcon fontSize="large" />}
                    placeholder="Riot Games"
                    minLength={3}
                    type="text"
                />

                <div className={styles.ContainerBotoes}>
                    <button className="btn btn-primary mt-4 me-3" onClick={atualizarDados}> {typeofAction === "Adicionar" ? "Adicionar" : "Editar"} </button>
                    <button className="btn btn-secondary mt-4 me-3" onClick={closeModalCoupon}> Voltar </button>
                </div>
            </Box>

        </Modal>
    )
}