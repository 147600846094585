import { useState } from "react";
import ICriarCampanha from "../../../../../types/ICriarCampanha";
import { Alert, Pagination, Stack } from "@mui/material";
import styles from "./TableDesktop.module.css";
import { copyToPaste } from "../../../../Influenciador/CampanhasDisponiveis/DetalhesCampanha/functions";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ModalCoupon from "./ModalCoupon";
import EditIcon from '@mui/icons-material/Edit';
import DiscountIcon from '@mui/icons-material/Discount';

interface TabelaDesktopProps {
    data: ICriarCampanha[],
    setAtualiza: (value: boolean) => void;
    atualiza: boolean
}

export default function TableDesktopCupons({ data, setAtualiza, atualiza }: TabelaDesktopProps) {
    // Campos modal
    const [nomeDaAcao, setNomeDaAcao] = useState("");
    const [campo, setCampo] = useState("");
    const [nomeCampanha, setNomeCampanha] = useState("");
    const [id, setId] = useState("");
    const [isEditado, setIsEditado] = useState("");
    // Controla os modais
    const [modalCoupon, setModalCoupon] = useState(false);
    // Paginação
    const [page, setPage] = useState(1);
    const handleChangePagina = (event: any, value: any) => {
        setPage(value);
    };
    // Botao copiar cupom
    const [showConfirmedPaste, setShowConfirmedPaste] = useState(false);
    const [rightShow, setRightShow] = useState("");

    const linesForPage = 15;
    const startIndex = (page - 1) * linesForPage;
    const endIndex = startIndex + linesForPage;
    const campanhasPaginados = data.slice(startIndex, endIndex);

    // Botão copiar cupom
    function callCopyButton(text: string) {
        copyToPaste(text);
        setShowConfirmedPaste(true);
        setRightShow(text);
    }

    const openModalCoupon = () => {
        setModalCoupon(true);
    }

    const closeModalCoupon = () => {
        setModalCoupon(false);
    }

    function addDataCoupon(id: string, field: string, nameOfCampaing: string, typeofAction: string) {
        setNomeDaAcao(typeofAction);
        setCampo(field);
        setNomeCampanha(nameOfCampaing)
        setId(id);
        openModalCoupon();
    }

    return (
        <Stack spacing={2}>
            {campanhasPaginados.length !== 0 ? <> <table className="table table-light table-striped text-center">
                <thead>
                    <tr>
                        <th scope="col" className="text-white d-none d-sm-table-cell" style={{ textAlign: 'center' }}> Nome da campanha </th>
                        <th scope="col" className="text-white d-none d-sm-table-cell" style={{ textAlign: 'center' }}> Cupom </th>
                        <th scope="col" className="text-white d-none d-sm-table-cell" style={{ textAlign: 'center' }}> Porcentagem </th>
                        <th scope="col" className="text-white d-none d-sm-table-cell" style={{ textAlign: 'center' }}> Validade do cupom </th>
                        <th scope="col" className="text-white d-none d-sm-table-cell" style={{ textAlign: 'center' }}> Status </th>
                        <th scope="col" className="text-white d-none d-sm-table-cell" style={{ textAlign: 'center' }}> Ações </th>
                    </tr>
                </thead>
                <tbody>
                    <ModalCoupon
                        openModal={modalCoupon}
                        openModalCoupon={closeModalCoupon}
                        closeModalCoupon={closeModalCoupon}
                        typeofAction={nomeDaAcao}
                        typeOfField={campo}
                        campaign={nomeCampanha}
                        id={id}
                        setAtualiza={setAtualiza}
                        atualiza={atualiza}
                    />
                    {campanhasPaginados.map((campaign, index) => (
                        <tr key={campaign.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                            <td className="align-middle text-center">
                                <a href={`/moderador/detalhes-campanha-performance?id=${campaign.id}`} className={styles.aTable}>
                                    {campaign.nomeDaCampanha}
                                </a>
                            </td>
                            <td className="align-middle text-center">
                                {campaign.cupom ? <> {campaign.cupom} <ContentCopyIcon onClick={() => callCopyButton(campaign.cupom)} className={styles.BotaoCopiarCupom} /> <EditIcon onClick={() => addDataCoupon(campaign.id, "cupom", campaign.nomeDaCampanha, "Editar")} className={styles.BotaoCopiarCupom} /> </> : <button className="btn btn-primary" onClick={() => addDataCoupon(campaign.id, "cupom", campaign.nomeDaCampanha, "Adicionar")}> Adicionar cupom </button>}
                                {showConfirmedPaste && rightShow === campaign.cupom && <span className={`${styles.Copiado} d-block`}> Cupom copiado! </span>}
                            </td>
                            <td className="align-middle text-center">
                                {campaign.descontoCupom
                                    ? campaign.descontoCupom.includes('%')
                                        ? <> {campaign.descontoCupom} <EditIcon onClick={() => addDataCoupon(campaign.id, "descontoCupom", campaign.nomeDaCampanha, "Editar")} className={styles.BotaoCopiarCupom} /> </>
                                        : <> {campaign.descontoCupom}% <EditIcon onClick={() => addDataCoupon(campaign.id, "descontoCupom", campaign.nomeDaCampanha, "Editar")} className={styles.BotaoCopiarCupom} /> </>
                                    : <button className="btn btn-primary" onClick={() => addDataCoupon(campaign.id, "descontoCupom", campaign.nomeDaCampanha, "Adicionar")}> Adicionar desconto </button>}
                            </td>

                            <td className="align-middle text-center">
                                {campaign.validadeDoCupom
                                    ? <> {new Date(campaign.validadeDoCupom + "T00:00:00").toLocaleDateString("pt-BR")} <EditIcon onClick={() => addDataCoupon(campaign.id, "validadeDoCupom", campaign.nomeDaCampanha, "Editar")} className={styles.BotaoCopiarCupom} /> </>
                                    : <button className="btn btn-primary" onClick={() => addDataCoupon(campaign.id, "validadeDoCupom", campaign.nomeDaCampanha, "Adicionar")}>
                                        Adicionar validade
                                    </button>
                                }
                            </td>

                            <td
                                className="align-middle text-center"
                                style={{
                                    color: !campaign.validadeDoCupom
                                        ? "#E9762B" // Laranja (Sem validade)
                                        : new Date(campaign.validadeDoCupom + "T00:00:00") >= new Date(new Date().setHours(0, 0, 0, 0))
                                            ? "#0D4715" // Verde (Na validade)
                                            : "#A31D1D" // Vermelho (Passou da validade)
                                }}
                            >
                                {!campaign.validadeDoCupom
                                    ? "Sem validade"
                                    : new Date(campaign.validadeDoCupom + "T00:00:00") >= new Date(new Date().setHours(0, 0, 0, 0))
                                        ? "Válido"
                                        : "Passou da validade"}
                            </td>
                            <td className="align-middle text-center">
                                <a href={`/moderador/cupons-nominais?idCampanha=${campaign.id}&nomeCampanha=${campaign.nomeDaCampanha}`} className={styles.BotaoCopiarCupom}> <DiscountIcon fontSize="medium" /> </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
                <nav className={styles.Pagination}>
                    <Pagination
                        count={Math.ceil(data.length / linesForPage)}
                        page={page}
                        onChange={handleChangePagina}
                    /></nav> </> : <Alert severity="warning"> Não existem campanhas sem performance no momento. </Alert>}
        </Stack>
    )
}


