import { useLocation } from "react-router-dom";
import styles from "./BuscaEstatisticasAPI.module.css";
import { buscarDadosApiEstatisicas } from "../../../utils/api";
import { useEffect, useState } from "react";
import { obterCodigoPorNomeCompleto, obterHojeESeteDiasAtras, obterNomeCompletoPorCodigo, obterNomesPorCodigos, obterPrimeiroEUltimoDiaDoMes } from "./functions";
import { Action } from "../../../interfaces/DadosEstatisticasProps";
import IAnunciante from "../../../types/IAnunciante";
import { obterTodasCampanhas, obterTodosAnunciantes, obterTodosLinksGerados, obterTodosTransactionID } from "../../../utils/db";
import autenticaStore from "../../../stores/autentica.store";
import ICriarCampanha from "../../../types/ICriarCampanha";
import TextField from "../../../components/TextField";
import EventNoteIcon from '@mui/icons-material/EventNote';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import BotaoFiltros from "./BotaoFiltros";
import ChoiceField from "../../../components/ChoiceField";
import { listaDeFiltroAgrupamentoEstatisticasDeCampanha, listaFiltroEstatisticasDeCampanha, listaObjetivosCampanha } from "../../../utils/listas";
import SmartFilterInfluenciador from "../../../components/SmartFilter/SmartFilterInfluenciador";
import Person2Icon from '@mui/icons-material/Person2';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import { addDoc, collection, query, getDocs } from "firebase/firestore/lite";
import Dot from "../../../assets/Dot";
import { db } from "../../../db/banco";
import { ITransactionID } from "../../../types/ITransactionID";
import { Pagination, Stack } from "@mui/material";
import Total from "./Total";
import TabelaTransacao from "./TabelaTransacao";
import Alertas from "../../../components/Alertas";
import BarChartIcon from '@mui/icons-material/BarChart';
import MissOfParameters from "../../../components/MissOfParameters";

export default function BuscaEstatisticasAPI() {
    // obtem o email(serve como id) do anunciante
    const email = autenticaStore.getEmail();
    // Pegar parametro da campanha
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const idCampanhaActionpay = params.get("id");
    const tipoUsuario = params.get("tp");
    const idInfluenciadorActionpay = params.get("idInflu");
    // Pega o da última semana, sendo o máximo de dias que a API permite.
    const { hoje, seteDiasAtras } = obterHojeESeteDiasAtras();
    const { primeiroDiaDoMes, ultimoDiaDoMes } = obterPrimeiroEUltimoDiaDoMes();
    // Recebe e trata os dados
    const [actions, setActions] = useState<Action[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    // Recebe o anunciante
    const [anunciante, setAnunciante] = useState<IAnunciante[]>([]);
    // Recebe os transactionID
    const [transactionIDs, setTransactionIDs] = useState<ITransactionID[]>([]);
    const [transactionIDsAux, setTransactionIDsAux] = useState<ITransactionID[]>([]);
    // Alertas
    const [credenciais, setCredenciais] = useState(false);
    // Recebe a campanha
    const [campanha, setCampanha] = useState<ICriarCampanha[]>([]);
    // Recebe a lista dos influenciadores

    const [influenciadoresListaFiltro, setInfluenciadoresListaFiltro] = useState([""]);
    // Filtros
    const [dataInicial, setDataInicial] = useState(primeiroDiaDoMes);
    const [dataFinal, setDataFinal] = useState(ultimoDiaDoMes);
    const [codigoInfluenciadorActionpay, setCodigoInfluenciadorActionpay] = useState("");
    // Demais filtros
    const [mostrarDemaisFiltros, setMostrarDemaisFiltros] = useState(false);
    // Filtrar por
    const [filtrarPor, setFiltrarPor] = useState("");
    const [filtroPorInfluenciador, setFiltroPorInfluenciador] = useState("");
    const [filtrarPorObjetivo, setFiltrarPorObjetivo] = useState("");
    // Saber se é moderador ou anunciante    
    const tipoUsuarioParametro = location.pathname.split('/')[1];
    // Agrupar
    const [agruparPor, setAgruparPor] = useState("");
    // Primeiro effect
    const useCollectionRefTransaction = collection(db, "transactionID");
    useEffect(() => {
        // Função que busca os uniqueid existentes no Firestore
        const fetchExistingActions = async (): Promise<string[]> => {
            const uniqueIdsFromFirestore: string[] = [];
            try {
                const q = query(useCollectionRefTransaction); // Busca todos os documentos da coleção
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    uniqueIdsFromFirestore.push(doc.data().uniqueid);
                });
            } catch (error) {
                console.error("Erro ao buscar ações no Firestore: ", error);
            }
            return uniqueIdsFromFirestore;
        };

        // Função para salvar novas ações no Firestore, ignorando duplicadas
        const saveNewActions = async (actions: Action[]) => {
            try {
                const existingUniqueIds = await fetchExistingActions();

                // Filtra as ações que ainda não existem no Firestore
                const newActions = actions.filter(action => !existingUniqueIds.includes(action.uniqueid));

                // Salva as novas ações
                if (newActions.length > 0) {
                    for (const action of newActions) {
                        await addDoc(useCollectionRefTransaction, {
                            uniqueid: action.uniqueid,
                            apid: action.apid,
                            date: action.date,
                            status: { id: action.status.id },
                            offer: action.offer,
                            subId1: action.subIds.subId1,
                            price: action.price,
                            priceCurrency: action.priceCurrency,
                            payment: action.payment,
                            paymentCurrency: action.paymentCurrency,
                            aim: action.aim
                        });
                    }
                    console.log("Novas ações salvas com sucesso!");
                } else {
                    console.log("Nenhuma nova ação para salvar.");
                }
            } catch (error) {
                console.error("Erro ao salvar novas ações no Firestore: ", error);
            }
        };

        // Função que busca todos os dados da última semana
        const fetchDataCompleto = async () => {
            try {
                const response = await buscarDadosApiEstatisicas(seteDiasAtras, hoje, idCampanhaActionpay ? idCampanhaActionpay : "");
                if (response) {
                    const actions = response.result?.actions || [];
                    setActions(actions);
                    await saveNewActions(actions);  // Salva apenas as novas ações no Firestore
                } else {
                    setError('Erro ao carregar os dados.');
                }
            } catch (error) {
                setError('Erro na chamada da API.');
            } finally {
                setLoading(false);  // Atualiza o estado de loading
            }
        };

        fetchDataCompleto();


    }, [seteDiasAtras, hoje, idCampanhaActionpay, tipoUsuario]);
    // Carrega dados do anunciante
    useEffect(() => {
        const carregarAnunciantes = async () => {
            const todosAnunciantes = await obterTodosAnunciantes();
            const filtrarSomenteEsseAnunciante = todosAnunciantes.filter(somenteEsseAnunciante => somenteEsseAnunciante.email === email);
            setAnunciante(filtrarSomenteEsseAnunciante);
        };
        carregarAnunciantes();
    }, [email]);
    // Carrega dados das transactionID
    useEffect(() => {
        const carregarTransactionID = async () => {
            const todosTransactionID = await obterTodosTransactionID();
            const somenteDessaCampanhaDesseMes = todosTransactionID.filter(somenteDessaCampanha => somenteDessaCampanha.offer === Number(idCampanhaActionpay)
                && somenteDessaCampanha.date >= dataInicial && somenteDessaCampanha.date <= dataInicial
            );
            const somenteDessaCampanha = todosTransactionID.filter(somenteDessaCampanha => somenteDessaCampanha.offer === Number(idCampanhaActionpay));
            if (tipoUsuario === "anun" || tipoUsuario === "mod") {
                setTransactionIDs(somenteDessaCampanhaDesseMes);
                setTransactionIDsAux(somenteDessaCampanha);
            } else {
                const somenteDesseInfluenciador = somenteDessaCampanhaDesseMes.filter(porIdInfluenciador => porIdInfluenciador.subId1 === idInfluenciadorActionpay);
                const somenteDesseInfluenciadorDesseMes = somenteDessaCampanhaDesseMes.filter(porIdInfluenciador => porIdInfluenciador.subId1
                    === idInfluenciadorActionpay && porIdInfluenciador.date >= dataInicial && porIdInfluenciador.date <= dataInicial
                );
                setTransactionIDs(somenteDesseInfluenciadorDesseMes);
                setTransactionIDsAux(somenteDesseInfluenciador);
            }
        };
        carregarTransactionID();
    }, [email]);
    // Carrega dados da campanha
    useEffect(() => {
        const carregarCampanhas = async () => {
            const todasCampanhas = await obterTodasCampanhas();
            const filtrarSomenteEsseCampanha = todasCampanhas.filter(somenteEssaCampanha => somenteEssaCampanha.codigoActionpay === idCampanhaActionpay);
            setCampanha(filtrarSomenteEsseCampanha);
        };
        carregarCampanhas();
    }, [email]);

    const filtrar = async () => {
        if (filtrarPor === "influenciador" || filtrarPor === "") {
            const recebeCodigo = await obterCodigoPorNomeCompleto(filtroPorInfluenciador);
            const filtrados: ITransactionID[] = transactionIDsAux.filter(filtro => {
                const receberData = filtro.date;
                const porInfluenciador = filtro.subId1;

                const dataConvertida = receberData.substring(0, 10);

                // Adicione verificação para o código
                return dataConvertida >= dataInicial && dataConvertida <= dataFinal && (porInfluenciador === String(recebeCodigo) || !recebeCodigo);
            });

            // Atualizar o estado com os dados filtrados
            setTransactionIDs(filtrados);
        }
        if (filtrarPor === "objetivo") {
            const filtrados: ITransactionID[] = transactionIDsAux.filter(filtro => {
                const receberData = filtro.date;              

                const dataConvertida = receberData.substring(0, 10);

                // Adicione verificação para o código
                return dataConvertida >= dataInicial && dataConvertida <= dataFinal
            });

            // Atualizar o estado com os dados filtrados
            setTransactionIDs(filtrados);
        }
    };
    // Mostrar demais filtros
    function alterarMostrarDemaisFiltros() {
        if (mostrarDemaisFiltros) {
            setCodigoInfluenciadorActionpay("");
            setFiltroPorInfluenciador("");
            setFiltrarPorObjetivo("");
            localStorage.removeItem('ultimoNomeInfluenciador'); // Limpa o valor salvo no localStorage
        }
        setMostrarDemaisFiltros(!mostrarDemaisFiltros);
    }

    useEffect(() => {
        const buscarLinksGeradosISM = async () => {
            const pegarLinksGeradosISM = await obterTodosLinksGerados();
            const somenteDessaCampanha = pegarLinksGeradosISM.filter(peloIdCampanhaActionpay => peloIdCampanhaActionpay.codigoDaCampanha === idCampanhaActionpay);
            const uniqueInfluencers = Array.from(new Set(somenteDessaCampanha.map(link => link.codigoDoInfluenciador)));
            const uniqueInfluencersNumbers = uniqueInfluencers.map(codigo => Number(codigo));
            const recebeLista = await obterNomesPorCodigos(uniqueInfluencersNumbers);

            setInfluenciadoresListaFiltro(recebeLista);
        }
        buscarLinksGeradosISM();
    }, [])

    useEffect(() => {

    }, [])

    // Paginação
    const [pagina, setPagina] = useState(1);

    const handleChangePagina = (event: any, value: any) => {
        setPagina(value);
    };
    const linhasPorPagina = 10;
    const startIndex = (pagina - 1) * linhasPorPagina;
    const endIndex = startIndex + linhasPorPagina;
    const transactionIDPaginados = transactionIDs.slice(startIndex, endIndex);     
    
    if (tipoUsuarioParametro === "anunciante") {
        if (idCampanhaActionpay === null || tipoUsuario === null) 
            return <MissOfParameters typeUser="anunciante" screen="estatisticas-da-campanha"
        /> 
    } else {
        if (idCampanhaActionpay === null || tipoUsuario === null || idInfluenciadorActionpay === null) 
            return <MissOfParameters typeUser="influenciador" screen="estatisticas-da-campanha"
        /> 
    }
    
    if (loading) return <Dot />;
    // Mostra pro usuário que deu erro.
    if (error) return <p>{error}</p>;

    return (
        <section className={styles.BuscaEstatisticasAPI}>
            <h2> Estatísticas de campanha {tipoUsuario === "anun" ? "do anunciante." : tipoUsuario === "influ" ? "do influenciador." : "visão do moderador."} </h2>

            {campanha.map(dados => (
                <h4> Aqui estão os dados referente à campanha: {dados.nomeDaCampanha}. </h4>
            ))}

            <h5> Filtros: </h5>

            <aside>
                <TextField
                    title=""
                    control={setDataInicial}
                    value={dataInicial}
                    placeholder="25/10/2023"
                    type="date"
                    icon={<EventNoteIcon fontSize="large" />}
                />
                <TextField
                    title=""
                    control={setDataFinal}
                    value={dataFinal}
                    placeholder="25/10/2023"
                    type="date"
                    icon={<EventNoteIcon fontSize="large" />}
                />
                <button className="btn btn-info" onClick={filtrar}> <FilterAltIcon /> Filtrar </button>
                {tipoUsuario !== "influ" && <BotaoFiltros mostrarDemaisFiltros={mostrarDemaisFiltros} alterarMostrarDemaisFiltros={alterarMostrarDemaisFiltros} />}
            </aside>

            {mostrarDemaisFiltros && <aside>
                <ChoiceField
                    title=""
                    control={setFiltrarPor}
                    icon={<FilterAltIcon fontSize="large" />}
                    list={listaFiltroEstatisticasDeCampanha}
                    defaultValue={filtrarPor ? filtrarPor : "Escolha"}
                />
                {filtrarPor === "influenciador" && <SmartFilterInfluenciador list={influenciadoresListaFiltro}
                    onInputChange={setFiltroPorInfluenciador} icon={<Person2Icon fontSize="large" />}
                />}

                {filtrarPor === "objetivo" && <ChoiceField list={listaObjetivosCampanha}
                    title="" control={setFiltrarPorObjetivo} icon={<BarChartIcon fontSize="large" />}
                    defaultValue="Escolha"
                />}

            </aside>}

            <h5> Agrupar por: </h5>

            <aside>
                <ChoiceField
                    title=""
                    control={setAgruparPor}
                    defaultValue={listaDeFiltroAgrupamentoEstatisticasDeCampanha[0]}
                    icon={<Diversity2Icon fontSize="large" />}
                    list={listaDeFiltroAgrupamentoEstatisticasDeCampanha}
                />

                <button className="btn btn-primary"> Agrupar </button>
            </aside>                    

            {transactionIDPaginados.length !== 0 ? <>
                <Stack spacing={2}>
                    
                    <TabelaTransacao
                        filter={filtrarPorObjetivo}
                        transactionIDPaginados={transactionIDPaginados}
                        tipoUsuario={tipoUsuario ? tipoUsuario : ""}
                        idCampanhaActionpay={idCampanhaActionpay ? idCampanhaActionpay : ""}
                    />
                    <Pagination
                        count={Math.ceil(transactionIDs.length / linhasPorPagina)}
                        page={pagina}
                        onChange={handleChangePagina}
                    />
                </Stack>

                <h6> Total </h6>

                <Stack spacing={2}>
                    <Total transactions={transactionIDPaginados} />
                </Stack>
            </> : <Alertas controle={setCredenciais} mensagem="Não existem transações para esse filtro." tipoMensagem="warning" />}
        </section>
    )
}