import { collection, getDocs } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { db } from "../../../../../db/banco";
import styles from "./CuponsNominais.module.css";
import ICriarCampanha from "../../../../../types/ICriarCampanha";
import Spinner from "../../../../../components/Spinner";
import TabelaDesktopCuponsNominais from "./TabelaDesktopCuponsNominais";
import IChatPropostas from "../../../../../types/ICharPropostas";
import { useLocation } from "react-router-dom";

export default function CuponsNominais() {
    const [campanha, setCampanha] = useState<IChatPropostas[]>([]);
    const [updateLoading, setUpadateLoading] = useState(true);
    const [atualiza, setAtualiza] = useState(false);
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("idCampanha");
    const nomeDaCampanha = params.get("nomeCampanha");
    

    const useCollectionRef = collection(db, "chatPropostas");
    useEffect(() => {
        const obterCampanhas = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: IChatPropostas[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IChatPropostas));
            const filtrarCampanhaParametro: IChatPropostas[] = todasCampanhas.filter(porId => porId.idCampanha === parametro && porId.statusProposta === "aceito");
            setCampanha(filtrarCampanhaParametro);            
            setUpadateLoading(false);
        }
        obterCampanhas();

    }, [atualiza]);
    return (
        <section className={styles.CuponsNominais}>
            <h2> Cupons nominais da campanha: {nomeDaCampanha} </h2>

            {updateLoading ? <Spinner /> : <TabelaDesktopCuponsNominais data={campanha} atualiza={atualiza} setAtualiza={setAtualiza}/>}
        </section>
    )
}