import { Pagination, Stack } from "@mui/material";
import { useState } from "react";
import IChatPropostas from "../../../../../../types/ICharPropostas";
import ModalCouponNominais from "./ModalCouponNominais";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyToPaste } from "../../../../../Influenciador/CampanhasDisponiveis/DetalhesCampanha/functions";
import styles from "../../TableDesktopCupons/TableDesktop.module.css"

interface TabelaDesktopProps {
    data: IChatPropostas[],
    setAtualiza: (value: boolean) => void;
    atualiza: boolean
}

export default function TabelaDesktopCuponsNominais({ data, atualiza, setAtualiza }: TabelaDesktopProps) {
    const [idChatProposta, setIdChatProposta] = useState("");
    const [nomeDaAcao, setNomeDaAcao] = useState("");
    const [nomeInfluenciador, setNomeInfluenciador] = useState("");
    // Controla os modais
    const [modalCoupon, setModalCoupon] = useState(false);
    // Botao copiar cupom
    const [showConfirmedPaste, setShowConfirmedPaste] = useState(false);
    const [rightShow, setRightShow] = useState("");

    // Paginação
    const [page, setPage] = useState(1);
    const handleChangePagina = (event: any, value: any) => {
        setPage(value);
    };
    const linesForPage = 15;
    const startIndex = (page - 1) * linesForPage;
    const endIndex = startIndex + linesForPage;
    const influenciadoresPaginados = data.slice(startIndex, endIndex);

    function addDataCoupon(idChatProposta: string, nomeDaAcao: string, NomeDaCampanha: string) {
        setIdChatProposta(idChatProposta);
        setNomeDaAcao(nomeDaAcao);
        setNomeInfluenciador(NomeDaCampanha);
        openModalCoupon();
    }

    const openModalCoupon = () => {
        setModalCoupon(true);
    }

    const closeModalCoupon = () => {
        setModalCoupon(false);
    }

    // Botão copiar cupom
    function callCopyButton(text: string) {
        copyToPaste(text);
        setShowConfirmedPaste(true);
        setRightShow(text);
    }

    return (
        <Stack spacing={2}>
            <table className="table table-light table-striped text-center">
                <thead>
                    <tr>
                        <th scope="col" className="text-white d-none d-sm-table-cell" style={{ textAlign: 'center' }}> Influenciadores </th>
                        <th scope="col" className="text-white d-none d-sm-table-cell" style={{ textAlign: 'center' }}> Cupom nominal </th>
                    </tr>
                </thead>
                <tbody>
                    <ModalCouponNominais
                        openModal={modalCoupon}
                        openModalCoupon={closeModalCoupon}
                        closeModalCoupon={closeModalCoupon}
                        typeofAction={nomeDaAcao}
                        influenciador={nomeInfluenciador}
                        id={idChatProposta}
                        setAtualiza={setAtualiza}
                        atualiza={atualiza}
                    />
                    {influenciadoresPaginados.map((influenciadores, index) => (
                        <tr key={influenciadores.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                            <td style={{ width: '33%' }}>
                                {influenciadores.nomeInfluenciador}
                            </td>
                            <td> {influenciadores.cupomNominal ? <> {influenciadores.cupomNominal} <ContentCopyIcon onClick={() => callCopyButton(influenciadores.cupomNominal)} className={styles.BotaoCopiarCupom} /> <button className="btn btn-primary" onClick={() => addDataCoupon(influenciadores.id, "Editar", influenciadores.nomeInfluenciador)}> Editar </button> </> : <button className="btn btn-primary" onClick={() => addDataCoupon(influenciadores.id, "Adicionar", influenciadores.nomeInfluenciador)}> Adicionar </button>}
                                {showConfirmedPaste && rightShow === influenciadores.cupomNominal && <span className={`${styles.Copiado} d-block`}> Cupom copiado! </span>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <nav className={styles.Pagination}>
                <Pagination
                    count={Math.ceil(data.length / linesForPage)}
                    page={page}
                    onChange={handleChangePagina}
                /></nav>
        </Stack>
    )
}