import { Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
import CampoTexto from "../../../../components/CampoTexto";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { addDoc, collection, CollectionReference, DocumentData } from "firebase/firestore/lite";
import { db } from "../../../../db/banco";
import { idText } from "typescript";


interface SelectedOption {
  value: string;
  checked: boolean;
}

interface EscolherCamposCupomProps {
  idCampanhaParametro: string
}

export default function EscolherCamposCupom({ idCampanhaParametro }: EscolherCamposCupomProps) {
  const listaDeAcoes = ["Nome do cupom", "Desconto do cupom", "Validade do cupom"];

  // Estado para armazenar os checkboxes selecionados
  const [selectedOptions, setSelectedOptions] = useState<Record<string, boolean>>({});

  // Estado para armazenar os valores dos inputs dinamicamente
  const [inputValues, setInputValues] = useState<Record<string, string>>({});

  // Mapeamento de ícones
  const iconMap: Record<string, JSX.Element> = {
    "Nome do cupom": <LocalActivityIcon fontSize="large" />,
    "Desconto do cupom": <ConfirmationNumberIcon fontSize="large" />,
    "Validade do cupom": <EventNoteIcon fontSize="large" />,
  };

  // Mapeamento de tipos de input
  const typeMap: Record<string, string> = {
    "Nome do cupom": "text",
    "Desconto do cupom": "text",
    "Validade do cupom": "date",
  };

  const handleCheckboxChange = (value: string) => {
    setSelectedOptions((prev) => {
      const updated = { ...prev, [value]: !prev[value] };

      // Se desmarcar, remover o valor do input correspondente
      if (!updated[value]) {
        setInputValues((prevInputs) => {
          const newInputs = { ...prevInputs };
          delete newInputs[value]; // Remove o campo do objeto
          return newInputs;
        });
      }

      return updated;
    });
  };

  const handleInputChange = (value: string, option: string) => {
    setInputValues((prev) => ({ ...prev, [option]: value }));
  };

  const useCollectionRef = collection(db, "solicitarCupom");

  async function criarSolicitacao(event: React.FormEvent) {
    event.preventDefault(); // Evita o comportamento padrão de envio do formulário

    try {
      // Adiciona um novo documento à coleção "solicitarCupom"
      const solicitarCupom = await addDoc(useCollectionRef, {
        cupom: inputValues["Nome do cupom"],
        descontoCupom: inputValues["Desconto do cupom"],
        validadeCupom: inputValues["Validade do cupom"],
        idCampanha: idCampanhaParametro,
        solicitacaoVista: false,
      });

      alert("Solicitação enviada com sucesso!");

      // Aqui você pode fazer algo com a resposta, por exemplo, pegar o ID do documento:
      console.log("Solicitação criada com ID:", solicitarCupom);
    } catch (error) {
      console.error("Erro ao enviar solicitação:", error);
      alert("Ocorreu um erro ao enviar a solicitação.");
    }
  }

  return (
    <form onSubmit={criarSolicitacao}>
      <h3>Escolher campos para solicitar troca.</h3>
      {listaDeAcoes.map((option) => (
        <FormControlLabel
          key={option}
          control={
            <Checkbox
              checked={!!selectedOptions[option]} // Garante que os checkboxes mantenham estado correto
              onChange={() => handleCheckboxChange(option)}
            />
          }
          label={option}
        />
      ))}

      {Object.keys(selectedOptions).some((key) => selectedOptions[key]) && (
        <h3>Selecionados para solicitação:</h3>
      )}

      <ul>
        {Object.keys(selectedOptions)
          .filter((opt) => selectedOptions[opt]) // Só renderiza os inputs das opções selecionadas
          .map((opt) => (
            <li key={opt}>
              <CampoTexto
                titulo={`Valor para ${opt}`}
                valor={inputValues[opt] || ""}
                controle={(valor) => handleInputChange(valor, opt)}
                icone={iconMap[opt]} // Ícone dinâmico baseado na opção
                placeholder={`Digite o ${opt.toLowerCase()}`}
                minLength={3}
                type={typeMap[opt]} // Define dinamicamente o tipo do input
              />
            </li>
          ))}
      </ul>
      <button className="btn btn-primary"> Enviar solicitação </button>
    </form>
  );
}


