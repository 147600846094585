import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { obterTodosAnunciantes, obterTodosInfluenciadores } from "../utils/db";

interface Iuser {
    email: string;
    typeUser: string;
}

class ChecksAcceptance {
    isAccepted = false;
    usuario: Iuser = { email: "", typeUser: "" };

    constructor() {
        makeAutoObservable(this, {
            isAccepted: observable,
            usuario: observable,
            login: action,
            logout: action,
        });

        // Restaurar estado do usuário e aceitação do localStorage
        const storedUser = localStorage.getItem("usuario");
        const storedAccepted = localStorage.getItem("isAccepted");

        if (storedUser) {
            this.usuario = JSON.parse(storedUser);
        }

        this.isAccepted = storedAccepted ? JSON.parse(storedAccepted) : false;
    }

    async login({ email, typeUser }: Iuser) {
        this.usuario = { email, typeUser };
        let aceitoStatus = false;

        if (typeUser === "anunciante") {
            aceitoStatus = await this.verificarAceitacao(email, obterTodosAnunciantes);
        } else {
            aceitoStatus = await this.verificarAceitacao(email, obterTodosInfluenciadores);
        }

        runInAction(() => {
            this.isAccepted = aceitoStatus;
            localStorage.setItem("usuario", JSON.stringify(this.usuario));
            localStorage.setItem("isAccepted", JSON.stringify(this.isAccepted));
        });
    }

    async verificarAceitacao(email: string, obterDados: () => Promise<any[]>) {
        try {
            const todos = await obterDados();
            return todos.some(usuario => usuario.email === email && usuario.aceito === "aceito");
        } catch (error) {
            console.error("Erro ao verificar aceitação:", error);
            return false;
        }
    }

    logout() {
        this.isAccepted = false;
        this.usuario = { email: "", typeUser: "" };

        // Limpar localStorage
        localStorage.removeItem("usuario");
        localStorage.removeItem("isAccepted");
    }

    getEmail(): string {
        return this.usuario.email;
    }
}

const checksAcceptance = new ChecksAcceptance();

export default checksAcceptance;
