export const mascaraCnpj = (value: string) => {
    return value
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{2})(\d+?)$/, "$1");
};

export const mascaraCpf = (value: string) => {
    // Remover caracteres não numéricos
    const cleanedValue = value.replace(/\D/g, "");

    // Limitar o comprimento ao tamanho máximo de um CPF (11 dígitos)
    const cpfDigits = cleanedValue.slice(0, 11);

    // Aplicar a máscara
    return cpfDigits
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
};


export const mascaraTelefone = (value: string) => {
    return value
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{4})(\d+?)$/, "$1");
};

export const mascaraCEP = (value: string): string => {
    return value
        .replace(/\D/g, "")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{3})(\d+?)$/, "$1");
};

export const mascaraDinheiro = (valor: string) => {
    let numero = valor.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    numero = numero.replace(/^0+/, ''); // Remove zeros à esquerda

    if (numero === '') {
        return 'R$ 0,00'; // Retorna o valor mínimo formatado
    }

    // Garante que haja pelo menos dois dígitos para os centavos
    while (numero.length < 3) {
        numero = '0' + numero;
    }

    const parteInteira = numero.slice(0, -2);
    const centavos = numero.slice(-2);

    return `R$ ${parteInteira},${centavos}`;
};

export const mascaraDinheiroSaldo = (valor: string) => {
    let numero = valor.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    numero = numero.replace(/^0+/, ''); // Remove zeros à esquerda

    if (numero === '') {
        return ''; // Retorna uma string vazia se não houver números
    }

    // Se tiver centavos, remove-os
    const indiceVirgula = numero.indexOf(',');
    if (indiceVirgula !== -1) {
        numero = numero.slice(0, indiceVirgula);
    }

    return `R$ ${numero}`; // Retorna o número formatado com o prefixo "R$"
};

export const mascaraPorcentagem = (value: any) => {
    // Remove todos os caracteres não numéricos, exceto a primeira vírgula
    let numericValue = value.replace(/[^\d,]/g, "");

    // Se houver mais de uma vírgula, substitui as extras por uma string vazia
    const commaCount = (numericValue.match(/,/g) || []).length;
    if (commaCount > 1) {
        numericValue = numericValue.replace(/,/g, (match: any, offset : any) => offset === numericValue.indexOf(',') ? match : '');
    }

    // Adiciona o símbolo de porcentagem no final
    return numericValue + "%";
};




