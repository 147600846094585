import { collection, getDocs } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { db } from "../../../db/banco";
import ICriarCampanha from "../../../types/ICriarCampanha";
import styles from "./SolicitarTrocaCupom.module.css";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Spinner from "../../../components/Spinner";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EscolherCamposCupom from "./EscolherCamposCupom";

export default function SolicitarTrocaCupom() {
    const [campanhas, setCampanhas] = useState<ICriarCampanha[]>([]);
    const [updateLoading, setUpdateLoading] = useState(true);
    const [atualiza, setAtualiza] = useState(false);

    // Pegar parâmetro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id"); // Parâmetro da página

    useEffect(() => {
        const obterCampanhas = async () => {
            const useCollectionRef = collection(db, "criarCampanha");
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as ICriarCampanha));
            const somenteDessaCampanha: ICriarCampanha[] = todasCampanhas.filter(porId => porId.id === parametro);
            setCampanhas(somenteDessaCampanha);
            setUpdateLoading(false);
        };
        obterCampanhas();
    }, [atualiza]);

    return (
        <section className={styles.SolicitarTrocaCupom}>
            <h2> Solicitar troca de cupom. </h2>
            <p> Local destinado para solicitar a troca de algum dado do cupom de sua campanha! </p>

            {updateLoading ? (
                <div className={styles.loadingContainer}>
                    <Spinner />
                </div>
            ) : (
                <form>
                    <h3> Dados de cupom já adicionados </h3>
                    {campanhas.map(campanha => (
                        campanha.cupom || campanha.descontoCupom || campanha.validadeDoCupom ? <>
                            {campanha.cupom ? <div>
                                <LocalActivityIcon fontSize="large" />
                                <label> <strong> Nome do cupom: </strong> {campanha.nomeDaCampanha} </label>
                            </div> : <span> Nome do cupom não adicionado </span>
                            }

                            {campanha.descontoCupom ? <div>
                                <ConfirmationNumberIcon fontSize="large" />
                                <label> <strong> Desconto do cupom: </strong> {campanha.descontoCupom} </label>
                            </div> : <span> Desconto do cupom não adicionado </span>
                            }

                            {campanha.validadeDoCupom ? <div>
                                <EventNoteIcon fontSize="large" />
                                <label> <strong> Validade do cupom: </strong> {new Date(campanha.validadeDoCupom).toLocaleDateString("pt-BR")} </label>
                            </div> : <span> Validade do cupom não adicionado </span>
                            }

                        </> : <span> Cupom não adicionado! </span>
                    ))}                    
                </form>
            )}

            <EscolherCamposCupom idCampanhaParametro={parametro ? parametro : ""}/>

        </section>
    );
}
