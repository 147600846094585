import React from 'react';

interface FormatNumberValuesProps {
  valor: number;
}

const FormatNumberValues: React.FC<FormatNumberValuesProps> = ({ valor }) => {
  const formatarValor = (valor: number): string => {
    return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  return <span>{formatarValor(valor)}</span>;
};

export default FormatNumberValues;
