import { Modal, Box, Typography, Alert, Button } from "@mui/material";
import { title } from "process";
import { Link } from "react-router-dom";
import { estiloModalPadrao } from "../../../../../../styles";
import CampoTexto from "../../../../../../components/CampoTexto";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useState } from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { doc, updateDoc } from "firebase/firestore/lite";
import { db } from "../../../../../../db/banco";
import styles from "./ModalsCoupon.module.css";

interface ModalsProps {
    openModal: boolean;
    openModalCoupon: () => void;
    closeModalCoupon: () => void;
    typeofAction: string;
    typeOfField: string;
    campaign: string;
    id: string;
    setAtualiza: (value: boolean) => void;
    atualiza: boolean
}

export default function ModalCoupon({ openModal, openModalCoupon, closeModalCoupon, typeofAction, typeOfField, campaign, id, setAtualiza, atualiza }: ModalsProps) {
    const [data, setData] = useState("");
    const [mensagemSucesso, setMensagemSucesso] = useState(false);

    function atualizarDados() {
        if (typeOfField === "cupom") {
            const campanha = {
                cupom: data
            }
            editarAnunciante(campanha);
        }

        if (typeOfField === "descontoCupom") {
            const campanha = {
                descontoCupom: data
            }
            editarAnunciante(campanha);
        }

        if (typeOfField === "validadeDoCupom") {
            const dataCorrigida = new Date(data);
            dataCorrigida.setMinutes(dataCorrigida.getMinutes() + dataCorrigida.getTimezoneOffset());

            const campanha = {
                validadeDoCupom: dataCorrigida.toISOString().split("T")[0] // Garante o formato "YYYY-MM-DD"
            };

            editarAnunciante(campanha);
        }
        setAtualiza(!atualiza);
    }

    async function editarAnunciante(novosDados: any) {
        try {
            const anuncianteRef = doc(db, "criarCampanha", id);
            await updateDoc(anuncianteRef, novosDados);
            setAtualiza(!atualiza);
            setData("")
            alert("Dados atualizados!")

        } catch (error) {

        }
    }

    return (
        <Modal
            open={openModal}
            onClose={openModalCoupon}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={estiloModalPadrao}>
                <p className={styles.Texto}> {typeofAction} {typeOfField === "cupom" ? "cupom " : typeOfField === "descontoCupom" ? "porcentagem do cupom " : "validade do cupom "}
                    da campanha {campaign}. </p>

                <CampoTexto
                    titulo="Cupom"
                    valor={data}
                    controle={(valor) => setData(valor)}
                    icone={typeofAction === "Adicionar" ? <AddCircleIcon fontSize="large" /> : <DriveFileRenameOutlineIcon fontSize="large" />}
                    placeholder="Riot Games"
                    minLength={3}
                    type={typeOfField === "validadeDoCupom" ? "date" : "text"}
                />

                <div className={styles.ContainerBotoes}>
                    <button className="btn btn-primary mt-4 me-3" onClick={atualizarDados}> {typeofAction === "Adicionar" ? "Adicionar" : "Editar"} </button>
                    <button className="btn btn-secondary mt-4 me-3" onClick={closeModalCoupon}> Voltar </button>
                </div>
            </Box>

        </Modal>
    )
}