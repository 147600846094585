import { ref, listAll, getDownloadURL } from "firebase/storage";
import { useEffect, useState } from "react";
import { storage } from "../../../../db/banco";
import { Alert, Pagination } from "@mui/material";
import styles from "./LoadBanners.module.css";

interface LoadBannersProps {
    email: string;
    idCampaign: string;
}

interface BannerData {
    url: string;
    validityDate: string;
}

export default function LoadBanners({ email, idCampaign }: LoadBannersProps) {
    const [bannersList, setBannersList] = useState<BannerData[]>([]);
    // Carregar lista de banners já salvos no Firebase
    useEffect(() => {
        const storagePath = ref(storage, `anunciante/${email}/campanhas/${idCampaign}/banners/`);
        listAll(storagePath).then((res) => {
            const urls = res.items.map((item) => getDownloadURL(item));
            Promise.all(urls).then((downloadURLs) => {
                const banners = downloadURLs.map((url) => {
                    // Cortar a data do nome do arquivo
                    const parts = url.split("_");
                    const datePart = parts[parts.length - 1].split(".")[0];  // Pega a data que está no nome do arquivo
                    return { url, validityDate: datePart };
                });
                setBannersList(banners);
            });
        });
    }, [email, idCampaign]);

    // Paginação
    const [page, setPage] = useState(1);

    const handleChangePagina = (event: any, value: any) => {
        setPage(value);
    };
    const linhasPorPagina = 20;
    const startIndex = (page - 1) * linhasPorPagina;
    const endIndex = startIndex + linhasPorPagina;
    const bannerPageds = bannersList.slice(startIndex, endIndex);

    return (
        <section className={styles.LoadBanners}>
            {bannersList.length !== 0 ? <h3> Lista de banners enviados </h3> : <Alert severity="warning"> Nenhum banner enviado! </Alert>}

            <div className={styles.imageList}>
                {bannerPageds.map((bannerData, index) => {
                    // Lista de extensões de imagem válidas
                    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp'];

                    // Verifica se a URL contém uma das extensões de imagem
                    const isImage = imageExtensions.some(extension => bannerData.url.includes(extension));

                    return (
                        <div key={index} className={styles.imageItem}>
                            <a href={bannerData.url} target="_blank" rel="noopener noreferrer">
                                {isImage ? (
                                    <img
                                        src={bannerData.url}
                                        alt={`Banner ${index + 1}`}
                                        className={styles.bannerImage}
                                    />
                                ) : (
                                    <div >
                                        <video width="100%" controls className={styles.bannerVideo}>
                                            <source src={bannerData.url} type="video/mp4" />
                                            Seu navegador não suporta o elemento de vídeo.
                                        </video>
                                    </div>
                                )}
                            </a>
                            <p>Validade: {bannerData.validityDate.split("-").join("/")}</p>
                        </div>
                    );
                })}
            </div>

            {bannersList.length !== 0 && <Pagination
                count={Math.ceil(bannersList.length / linhasPorPagina)}
                page={page}
                onChange={handleChangePagina}
            />}

        </section>
    )
}