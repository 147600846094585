import { collection, getDocs } from "firebase/firestore/lite";
import { useState, useEffect } from "react";
import { db } from "../../../../db/banco";
import ICriarCampanha from "../../../../types/ICriarCampanha";
import { listaTiposDeCampanha } from "../../../../utils/listas";
import styles from "./Cupons.module.css";
import TableDesktopCupons from "./TableDesktopCupons";
import Spinner from "../../../../components/Spinner";

export default function Cupons() {
    const [campanhas, setCampanhas] = useState<ICriarCampanha[]>([]);
    const [updateLoading, setUpadateLoading] = useState(true);
    const [atualiza, setAtualiza] = useState(false);

    const useCollectionRef = collection(db, "criarCampanha");
    useEffect(() => {
        const obterCampanhas = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as ICriarCampanha));
            setCampanhas(todasCampanhas);
            setUpadateLoading(false);
        }
        obterCampanhas();

    }, [atualiza]);
    return (
        <section className={styles.Cupons}>
            <h2> Tela administrativa de cupons </h2>

            {updateLoading ? <Spinner /> : <TableDesktopCupons data={campanhas} setAtualiza={setAtualiza} atualiza={atualiza} />}
        </section>
    )
}